<template>
	<div class="add-wallet flex-column">
		<div class="card">
			<h2 style="display:flex; justify-content:space-between;">
				<span>Passphrase</span>
				<span>Keyfile</span>
			</h2>
			<div class="flex-column">
				<InputData v-model="passphraseInput" type="keyfile" :disabled="isCreatingWallet" placeholder="Type passphrase or import keyfile" autocapitalize="none" />
				<div />
				<Button v-if="!isCreatingWallet && !passphraseInput.length" @click="create" :disabled="passphraseInput.length && !isPassphrase" class="main" :glow="true">Create new wallet</Button>
				<Button v-else-if="isCreatingWallet" :disabled="createdWallet == null" @click="goToCreatedWallet" :icon="createdWallet == null ? 'loader' : ''" class="main" :glow="true">{{ createdWallet == null ? 'Generating, write down the passphrase' : 'Passphrase saved? Click here to proceed' }}</Button>
				<Button v-else :disabled="!isPassphrase || isGeneratingWallet" @click="confirmPassphrase" class="main" :glow="true">Import passphrase</Button>
			</div>
			<OverlayPrompt :options="popup">
				<div v-if="popup.messageType === 'invalid'" style="text-align: center">
					<p>This passphrase was not generated by wallet.chivesweave.org</p>
					<p>Make sure that it was entered correctly, without typing errors or extra characters. You can still import the wallet and use it normally</p>
				</div>
			</OverlayPrompt>
		</div>
		<div class="card">
			<h2 class="flex-row" style="align-items: center;"><Icon :icon="ICON.search" /><span>Watch public address</span></h2>
			<InputAddress v-model="targetInput" :submit="importAddressOnlyAction" />
			<h2 class="flex-row" style="align-items: center;"><Icon :icon="ICON.snow" /><span>Permafrost Vault</span></h2>
			<Button v-bind="coldWalletAction" class="main" :glow="true">{{ coldWalletAction.name }}</Button>
		</div>
	</div>
</template>



<script setup lang="ts">
import InputData from '@/components/form/InputData.vue'
import InputAddress from '@/components/form/InputAddress.vue';
import Button from '@/components/atomic/Button.vue'
import Icon from '@/components/atomic/Icon.vue'
import OverlayPrompt from '@/components/layout/OverlayPrompt.vue'
import Viewport from '@/components/layout/Viewport.vue'
import WalletSelector from '@/components/composed/WalletSelector.vue'
import { hardwareProviders, addAddress, addMnemonic, generateMnemonic, validateMnemonic } from '@/functions/Wallets'

import { notify } from '@/store/NotificationStore'
import { computed, ref } from 'vue'
import { useRouter } from '@/router'
import { ICON } from '@/store/Theme'
import { getColdWalletAction } from '@/store/Cold'

const router = useRouter()
const passphraseInput = ref('')
const targetInput = ref('')
// const maskAddress = (address) => { return address.match(/^[a-z0-9_-]{0,43}$/i) }
const popup = ref(undefined as undefined | object)
const isPassphrase = computed(() => passphraseInput.value.trim().split(/\s+/g).length >= 12)
const isCreatingWallet = ref(false)
const isGeneratingWallet = ref(false)
const createdWallet = ref(null as null | WalletDataInterface)
const create = async () => {
	isCreatingWallet.value = true
	passphraseInput.value = await generateMnemonic()
	const walletData = addMnemonic(passphraseInput.value)
	setTimeout(async () => createdWallet.value = await walletData, 10000)
	//track.account('Account Create')
}
const goToCreatedWallet = () => {
	if (!createdWallet.value) { return }
	router.push({ name: 'EditWallet', query: { wallet: createdWallet.value.id } })
}
const importPassphrase = async () => {
	isGeneratingWallet.value = true
	const walletData = addMnemonic(passphraseInput.value)
	popup.value = {
		icon: 'loader',
		message: 'importing',
	}
	router.push({ name: 'EditWallet', query: { wallet: (await walletData).id } })
	//track.account('Account Import')
}
const confirmPassphrase = async () => {
	if (await validateMnemonic(passphraseInput.value)) { return importPassphrase() }
	popup.value = {
		messageType: 'invalid',
		actions: [
			{ name: 'Back', run: () => popup.value = undefined },
			{ name: 'Import', run: () => importPassphrase() },
		]
	}
}
const importProvider = async (provider: AnyProvider) => {
	try {
		const walletData = await addAddress(undefined, provider)
		router.push({ name: 'EditWallet', query: { wallet: walletData.id } })
		//track.account('Account Ledger')
	} catch (e: any) { console.error(e); notify.error(e.message || e) }
}
const importAddressOnlyAction = { icon: ICON.addBox, run: async () => {
	const walletData = await addAddress(targetInput.value)
	router.push({ name: 'EditWallet', query: { wallet: walletData.id } })
	//track.account('Account Watch')
}}
const activeSettings = ref(-1)
const coldWalletAction = computed(() => getColdWalletAction(false))
</script>



<style scoped>
.add-wallet {
	width: 100%;
	min-height: var(--current-vh);
	padding: var(--spacing);
	align-items: center;
}

.add-wallet > .card {
	width: 100%;
	max-width: var(--column-width);
	overflow: hidden;
}

.input-data {
	/*text-align: center;*/
}

.button.main {
	height: 4.5rem;
	font-size: 1.1rem;
	width: 100%;
}

.popup {
	display: flex;
	align-items: center;
	justify-content: center;
}

.popup .card {
	height: var(--popup-width);
	display: flex;
	overflow: hidden;
	background: var(--background);
}
</style>